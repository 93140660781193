import { RouteRecordRaw, RouterView } from 'vue-router'

const routes: RouteRecordRaw = {
  path: 'NBentrustedInspection',
  name: 'NBentrustedInspection',
  component: RouterView,
  children: [
    {
      path: 'workBench',
      name: 'entrustedWorkBench',
      component: () => import(/* webpackChunkName: "entrustedWorkBench" */ '@/views/customsClearance/workBench/index.vue')
    },
    {
      path: 'myApplication',
      name: 'NBentrustedMyApplication',
      component: RouterView,
      children: [
        {
          path: 'fillInApplication',
          name: 'NBentrustedFillInApplication',
          component: () => import(/* webpackChunkName: "entrustedFillInApplication" */ '@/components/applicationForm/applicationInfo.vue')
        },
        {
          path: 'fillInApplication/selectType',
          name: 'NBentrustedSelectType',
          component: () => import(/* webpackChunkName: "entrustedSelectType" */ '@/views/NBentrustedInspection/myApplication/selectType/index.vue')
        },
        {
          path: 'fillInApplication/entrustedForm',
          name: 'NBentrustedForm',
          component: () => import(/* webpackChunkName: "entrustedForm" */ '@/views/NBentrustedInspection/myApplication/entrustedForm/index.vue')
        },
        {
          path: 'fillInApplication/entrustedForm/:id',
          name: 'NBentrustedFormDetail',
          component: () => import(/* webpackChunkName: "entrustedFormDetail" */ '@/views/NBentrustedInspection/myApplication/entrustedForm/index.vue')
        },
        {
          path: 'entrustedDrafts',
          name: 'NBentrustedDrafts',
          component: () => import(/* webpackChunkName: "entrustedDrafts" */ '@/views/NBentrustedInspection/entrustedDrafts/index.vue')
        },
        {
          path: 'entrustedList',
          name: 'NBentrustedList',
          component: () => import(/* webpackChunkName: "entrustedList" */ '@/views/NBentrustedInspection/entrustedList/index.vue')
        }
      ]
    },
    {
      path: 'myCertificate',
      name: 'NBentrustedCertificate',
      component: () => import(/* webpackChunkName: "entrustedCertificate" */ '@/views/NBentrustedInspection/entrustedCertificate/index.vue')
    },
    {
      path: 'myCertificate/:rfcNo',
      name: 'NBentrustedCertificateDetail',
      component: () => import(/* webpackChunkName: "entrustedCertificateDetail" */ '@/views/NBentrustedInspection/entrustedCertificate/detail.vue')
    }
  ]
}

export default routes
